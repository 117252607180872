import type { FC, PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import type { NursingHomeProfileDto } from '@pflegenavi/shared/api';
import { useClientStoredResourcesContext } from '@pflegenavi/frontend/client-stored-resources-context';
import { NursingHomeContext } from './NursingHomeContext';

interface NursingHomeProviderProps extends PropsWithChildren {
  /**
   * Optional function to get query parameters. Used to support both web (URLSearchParams) and mobile environments.
   * If not provided, nursing home selection from URL will be disabled.
   */
  getQueryParam?: (param: string) => string | null;
}

export const NursingHomeProvider: FC<NursingHomeProviderProps> = ({
  children,
  getQueryParam,
}) => {
  const resources = useClientStoredResourcesContext();

  const [selectedNursingHome, setSelectedNursingHome] = useState<
    NursingHomeProfileDto | undefined
  >(undefined);

  const [nursingHomeList, setNursingHomeListInternal] = useState<
    NursingHomeProfileDto[]
  >([]);

  useEffect(() => {
    resources.registerResource('selectedNursingHomeId');
    resources.registerResource('selectedNursingHome');
  }, [resources]);

  useEffect(() => {
    // Pre-load the nursing home information in case we have it.
    (async function () {
      const nursingHomeJson = await resources.get('selectedNursingHome');
      if (!nursingHomeJson) {
        return;
      }
      setSelectedNursingHome((nursingHome) => {
        // We already received data from the backend - do not overwrite with storage data.
        if (nursingHome) {
          return nursingHome;
        }
        return JSON.parse(nursingHomeJson);
      });
    })();
  }, [resources]);

  const initNursingHome = async (nursingHome: NursingHomeProfileDto) => {
    await resources.set('selectedNursingHomeId', nursingHome.id);
    await resources.set('selectedNursingHome', JSON.stringify(nursingHome));
    setSelectedNursingHome(nursingHome);
  };

  const setNursingHomeList = async (list: NursingHomeProfileDto[]) => {
    setNursingHomeListInternal(list);

    // First try to get nursing home from URL if getQueryParam is provided
    if (getQueryParam) {
      const urlNursingHomeId = getQueryParam('nh_id');
      if (urlNursingHomeId) {
        const nursingHomeFromUrl = list.find(
          (item) => item.id === urlNursingHomeId
        );
        if (nursingHomeFromUrl) {
          await initNursingHome(nursingHomeFromUrl);
          return;
        }
      }
    }

    // If no nursing home found in URL, try to get from stored ID
    const storedNursingHomeId = await resources.get('selectedNursingHomeId');
    if (storedNursingHomeId) {
      const storedNursingHome = list.find(
        (item) => item.id === storedNursingHomeId
      );
      if (storedNursingHome) {
        await initNursingHome(storedNursingHome);
        return;
      }
    }

    // If no stored nursing home found, select the first one if available
    if (list.length > 0) {
      await initNursingHome(list[0]);
    }
  };

  return (
    <NursingHomeContext.Provider
      value={{
        selectedNursingHome,
        initNursingHome,
        nursingHomeList,
        setNursingHomeList,
      }}
    >
      {children}
    </NursingHomeContext.Provider>
  );
};

/**
 * This is a dummy provider for the family member apps until we need to fetch the nursing home (e.g. for feature flags)
 */
export const EmptyNursingHomeProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <NursingHomeContext.Provider
      value={{
        selectedNursingHome: undefined,
        initNursingHome: () => {
          return Promise.resolve();
        },
        nursingHomeList: [],
        setNursingHomeList: () => {
          return;
        },
      }}
    >
      {children}
    </NursingHomeContext.Provider>
  );
};
