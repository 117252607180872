import type { NursingHomeProfileDto } from '@pflegenavi/shared/api';
import { createContext, useContext, useState } from 'react';

interface NursingHomeContextProps {
  selectedNursingHome: NursingHomeProfileDto | undefined;
  initNursingHome: (NursingHome: NursingHomeProfileDto) => Promise<void>;
  nursingHomeList: NursingHomeProfileDto[];
  // TODO: Why are we not fetching the nursing home list inside this NursingHomeProvider?
  setNursingHomeList: (nursingHomeList: NursingHomeProfileDto[]) => void;
}

export const NursingHomeContext = createContext<
  NursingHomeContextProps | undefined
>(undefined);

export const useNursingHomeContext = (): NursingHomeContextProps => {
  const context = useContext(NursingHomeContext);
  if (!context) {
    throw new Error('Missing NursingHomeProvider!');
  }
  return context;
};

export const useNursingHomeContextWithSuspense = (): Omit<
  NursingHomeContextProps,
  'selectedNursingHome'
> & {
  selectedNursingHome: NursingHomeProfileDto;
} => {
  const context = useNursingHomeContext();
  const [isWaiting, setIsWaiting] = useState(
    () => !context.selectedNursingHome
  );

  if (isWaiting) {
    throw new Promise((resolve) => {
      const checkNursingHome = () => {
        if (context.selectedNursingHome) {
          setIsWaiting(false);
          resolve(undefined);
        } else {
          requestAnimationFrame(checkNursingHome);
        }
      };
      checkNursingHome();
    });
  }

  return {
    ...context,
    selectedNursingHome: context.selectedNursingHome as NursingHomeProfileDto,
  };
};
