import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import React, { useState } from 'react';
import type { UseQueryResult } from '@tanstack/react-query';
import { useFileUrlToTypeMapping } from '@pflegenavi/web-components';
import { EnlargeImageGallery } from './EnlargeImageGallery';

interface ImageSet {
  imageIds: string[];
  title: string | undefined;
  subTitle?: string;
}

interface EnlargeImageSetGalleryProps {
  imageLoader: (
    requiredImageIds: string[] | undefined
  ) => UseQueryResult<
    { [key: string]: string | undefined } | undefined,
    unknown
  >;
  imageSets: ImageSet[];
  openImageSetIndex?: number;
  open: boolean;
  handleClose: () => void;
}

export const EnlargeImageSetGallery: FC<EnlargeImageSetGalleryProps> = ({
  imageLoader,
  imageSets,
  openImageSetIndex,
  open,
  handleClose,
}) => {
  const [previousOpenend, setPreviousOpened] = useState(false);
  const [displayedImageSetIndex, setDisplayedImageSetIndex] = useState(0);

  useEffect(() => {
    if (openImageSetIndex !== undefined) {
      setDisplayedImageSetIndex(openImageSetIndex);
    }
  }, [openImageSetIndex, open]);

  const { data: receiptImagesObject, isPending: ImageUrlsLoading } =
    imageLoader(
      displayedImageSetIndex !== undefined && imageSets
        ? imageSets[displayedImageSetIndex]?.imageIds
        : undefined
    );

  const receiptImageUrls: string[] | undefined = useMemo(() => {
    if (receiptImagesObject) {
      return Object.values(receiptImagesObject)
        .filter((item) => item !== undefined)
        .map((item) => item?.toString() ?? '');
    }
    return undefined;
  }, [receiptImagesObject]);

  const imageUrlToTypeMap = useFileUrlToTypeMapping(receiptImageUrls);

  const handleReceiptGalleryPreviousSet = () => {
    if (displayedImageSetIndex === 0) {
      return;
    }

    setPreviousOpened(true);
    setDisplayedImageSetIndex((displayedImageSetIndex) =>
      displayedImageSetIndex - 1 >= 0
        ? displayedImageSetIndex - 1
        : displayedImageSetIndex
    );
  };

  const handleReceiptGalleryNextSet = () => {
    if (displayedImageSetIndex === imageSets.length - 1) {
      return;
    }

    setPreviousOpened(false);
    setDisplayedImageSetIndex((displayedImageSetIndex) =>
      displayedImageSetIndex + 1 <= imageSets.length - 1
        ? displayedImageSetIndex + 1
        : displayedImageSetIndex
    );
  };

  return (
    <EnlargeImageGallery
      loading={ImageUrlsLoading}
      open={open}
      handleClose={handleClose}
      handleNextSet={() => handleReceiptGalleryNextSet()}
      handlePreviousSet={() => handleReceiptGalleryPreviousSet()}
      initialSelectedIndex={
        previousOpenend && receiptImageUrls !== undefined
          ? receiptImageUrls.length - 1
          : 0
      }
      imageUrls={receiptImageUrls || []}
      imageUrlToTypeMap={imageUrlToTypeMap}
      title={imageSets ? imageSets[displayedImageSetIndex]?.title : undefined}
      subTitle={
        imageSets ? imageSets[displayedImageSetIndex]?.subTitle : undefined
      }
      disableRightArrow={displayedImageSetIndex === imageSets.length - 1}
      disableLeftArrow={displayedImageSetIndex === 0}
    />
  );
};
